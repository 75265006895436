import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const StatusBanner = makeShortcode("StatusBanner");
const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-confiança",
      "style": {
        "position": "relative"
      }
    }}>{`✍️ Confiança`}<a parentName="h1" {...{
        "href": "#%EF%B8%8F-confian%C3%A7a",
        "aria-label": "️ confiança permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`O que é confiança? Em quem você confia e por quê? Um mundo sem confiança.`}</p>
    <h2 {...{
      "id": "mentirosos-e-golpistas",
      "style": {
        "position": "relative"
      }
    }}>{`Mentirosos e golpistas`}<a parentName="h2" {...{
        "href": "#mentirosos-e-golpistas",
        "aria-label": "mentirosos e golpistas permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`O Bitcoin começou com a máxima "`}<strong parentName="p">{`Não confie, verifique`}</strong>{`".`}</p>
    <p>{`Isso significa que a confiança começa apenas onde a verificação termina. A  confiança só tem significado a partir do momento que compreendemos totalmente como as pessoas podem mentir.`}</p>
    <p>{`Porque podemos transformar em código as possibilidades de trapaça, podemos também escrever regras de software executáveis com resultados determinísticos que previnem fraudes dentro dos protocolos que usamos para definir e transferir valor.`}</p>
    <p>{`Isto demonstra como utilizar `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern"
      }}>{`opostos complementares`}</a>{` como um modo de pensar para identificar padrões de significado. Podemos construir protocolos com fortes garantias de confiança, definindo claramente, e codificando, o que significa mentir. Para compreender a confiança, é preciso conhecer os detalhes de todas as possíveis fraudes.`}</p>
    <p>{`Estas regras claramente definidas e codificadas não significam que não precisamos mais confiar em nada. Isso significa que:`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 há uma mudança implícita em confiar naqueles que são donos das mídias pelas quais transferimos valores, para aqueles com os quais estamos de fato negociando.`}</p>
    </blockquote>
    <p>{`Tire um momento para refletir nessa frase.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Painel: Como podemos construir protocolos com fortes garantias de confiança?`}</p>
        <p>{`Definindo com clareza e codificando o que significa mentir.`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "vires-in-numeris",
      "style": {
        "position": "relative"
      }
    }}>{`Vires in numeris`}<a parentName="h2" {...{
        "href": "#vires-in-numeris",
        "aria-label": "vires in numeris permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`A frase acima é outro mote inicial da Bitcoin que significa "força em números".`}</p>
    <p>{`E data de Pitágoras, para quem "Todas as coisas são números". Essa mudança implícita, no sentido de confiar naqueles com quem estamos realmente negociando, acontece devido a uma mudança fundamental na linguagem pela qual o valor é definido. Em vez de uma regulamentação tradicional, imposta por prosa jurídica e tribunais humanos apoiados pela ameaça de violência; O Bitcoin permite que uma rede de pares crie as condições necessárias para que uma moeda funcione apenas através da matemática, imposta por computação determinista.`}</p>
    <p>{`O ditado sustenta este duplo sentido que implica que: tanto a utilização de números, quanto o consenso matemático, nos dão forças para estabelecer o que Andreas Antonopoulos chamará mais tarde de "fatos inatacáveis", ao mesmo tempo em que aponta para aquilo que dá poder e significado a qualquer narrativa: a comunidade que acredita nela. Quanto mais sucintamente podemos expressar verdades comuns, mais fácil se torna verificar (e, portanto, confiar) nos sistemas que usamos. Isto implica que:`}</p>
    <blockquote>
      <p parentName="blockquote">{`💡 Confiança está ligada a verdade.`}</p>
    </blockquote>
    <p>{`Uma verdade verificável publicamente significa que mais humanos podem chegar a um consenso sobre o estado básico de suas realidades comuns e continuar transacionando de maneiras cada vez mais valiosas com base em novos tipos de confiança.`}</p>
    <p>{`É um loop de um feedback profundo: use uma linguagem universal (matemática) para definir sucintamente o que significa trapacear, aplique sanções de forma determinista e executável com base em suas definições (código), isso permite que mais humanos experienciem a confiança interpessoal de formas antes inimagináveis.`}</p>
    <p>{`Para sonhar ideias importantes você deve pensar como um idealista; para construir sistemas que estejam à altura desses sonhos, `}<a parentName="p" {...{
        "href": "https://theconvivialsociety.substack.com/p/the-questions-concerning-technology"
      }}>{`você deve pensar como seu adversário`}</a>{`.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Painel: Como podemos tornar mais fácil a verificação e a confiança nos sistemas que usamos?`}</p>
        <p>{`Expressando sucintamente uma verdade comum.`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "acessibilidade",
      "style": {
        "position": "relative"
      }
    }}>{`Acessibilidade`}<a parentName="h2" {...{
        "href": "#acessibilidade",
        "aria-label": "acessibilidade permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Por fim, não estamos interessados na confiança baseada na fé cega. Estamos interessados na averiguação entre pares. O código fonte Bitcoin pode ser auditado por qualquer pessoa, em qualquer lugar do mundo - portanto, não é apenas na habilidade que estamos interessados aqui, mas também no acesso. A confiança, num sentido prático do dia-a-dia, tem muito a ver com transparência e educação.`}</p>
    <p>{`Isto requer um certo tipo de inovação arquitetônica na própria estrutura do dinheiro: não mais clientes e servidores; todos precisam ser pares. Por favor, leia a próxima parte do material curador - `}<a parentName="p" {...{
        "href": "/learn/module-0/money-language"
      }}>{`Money Talks`}</a>{` - para ter uma noção do que é uma arquitetura de dinheiro entre pares, e o que dinheiro como protocolo pode vir a significar em nosso tempo. Como Nicky Case destaca abaixo, tudo se resume a esta visão teórica fundamental do jogo:`}</p>
    <StatusBanner warning mdxType="StatusBanner">
      <p>{`Somos o meio-ambiente um do outro.`}</p>
    </StatusBanner>
    <hr></hr>
    <h2 {...{
      "id": "confiança-e-evidência",
      "style": {
        "position": "relative"
      }
    }}>{`Confiança e Evidência`}<a parentName="h2" {...{
        "href": "#confian%C3%A7a-e-evid%C3%AAncia",
        "aria-label": "confiança e evidência permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Qualquer palavra é compreendida em virtude da experiência compartilhada. Quando você e eu vemos alguém usando um objeto em sua cabeça o protegendo do sol, podemos conversar significativamente um com o outro usando a palavra 'chapéu'. Isto é claro o suficiente para palavras como "chapéu" ou "gato" ou "sentado". Entretanto, e com palavras como "confiança"? Como podemos cultivar a confiança em nossos ambientes quando somos o ambiente um do outro? Se você tentar forçar ou manipular os outros a confiarem em você (como se você tentasse forçar os outros a amá-lo), isso geralmente resulta em maior desconfiança.`}</p>
    <p>{`Joan contribuiu com uma linda perspectiva sobre esta questão - A confiança é cultivada entre nós quando, juntos, `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=KyVzykB35Ac"
      }}>{`prestamos atenção ao que é evidente`}</a>{`, e ela floresce quando podemos:`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Acolher a oposição`}</strong>{`. O pensamento quântico deve ser aprendido através da prática, e a discordância pode ser uma fonte de energia extremamente valiosa, necessária para o aprendizado e o crescimento. O verdadeiro aprendizado não está gravado em um currículo, esperando que o estudante o memorize; o verdadeiro aprendizado é o processo de transformação que ocorre dentro da pessoa que, intransigentemente, puxa o currículo, mastiga os pedaços, cospe os pedaços que não estão a seu gosto (atual) e digere o resto em uma compreensão crescente do mundo, que só pode ser muito subjetiva. As crianças sabem como fazer isso; são os adultos que as desencaminham, exigindo que as crianças 'confiem' em tudo o que lhes é alimentado. Seria melhor ensinar as crianças a confiar em seu próprio processo e nas pessoas que acolhem suas perguntas e objeções.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Diga "por favor" e "obrigado". Tome seu tempo. Abra seu coração. Ofereça presentes atenciosos. Peça conselhos, opiniões e ideias. E, sem dúvidas, use e fale de dinheiro. Há muitas maneiras de comunicar valor. Acho que muitos de nós temos o péssimo hábito de exigir respeito sem nunca dar nada em troca. Não creio que nenhum de nós possa aprender sem experimentar, isto é, receber primeiro.`}</p>
    </blockquote>
    <h3 {...{
      "id": "referências-adicionais",
      "style": {
        "position": "relative"
      }
    }}>{`Referências adicionais`}<a parentName="h3" {...{
        "href": "#refer%C3%AAncias-adicionais",
        "aria-label": "referências adicionais permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p>{`Confiança`}</p>
        <p>{`Este é um jogo online maravilhosamente interativo, projetado para ensinar tudo o que você precisa saber sobre teoria dos jogos. Aproveite!`}</p>
        <Aligner center mdxType="Aligner">
          <Button to="https://ncase.me/trust/" mdxType="Button">
    Jogar
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <hr></hr>
    <p>{`O nosso objetivo é que a Kernel seja uma comunidade colaborativa de alta confiabilidade entre colegas muito talentosos que compartilham da mesma mentalidade. Alguns dos principais aprendizados do jogo definem como pensamos sobre o programa, assim como o espaço da Web3 de modo mais geral.`}</p>
    <p><a parentName="p" {...{
        "href": "https://youtu.be/5H164LqEwiA?t=2711"
      }}><img parentName="a" {...{
          "src": "/images/mod0-img/trust1.png",
          "alt": "Trust 1"
        }}></img></a></p>
    <p><img parentName="p" {...{
        "src": "/images/mod0-img/trust2.png",
        "alt": "Trust 2"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/images/mod0-img/trust3.png",
        "alt": "Trust 3"
      }}></img></p>
    <p>{`Tudo isso levanta a questão: "O que é verdade?". Tudo o que podemos dizer é que, nosso interesse aqui é em verdades compartilhadas. A Verdade Absoluta está além do escopo deste curso, embora você possa usar a mesma estrutura para pensar sobre isso. Ninguém se aproxima de um absoluto tentando defini-lo, mas através da clareza do que não é. Se você for honesto e claro o suficiente, então o que sobrar depois de toda sua `}<a parentName="p" {...{
        "href": "https://openjournals.library.sydney.edu.au/index.php/SSR/article/viewFile/202/181"
      }}>{`negação`}</a>{` se aproxima muito mais da verdade.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      